import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Обучение английскому языку онлайн в школе English Lifestyle с Евгенией Руденко",
  "description": "👩‍🏫 Обучение английскому языку онлайн за 2 месяца в школе English Lifestyle с Евгенией Руденко. Изучай английский язык удаленно.",
  "image": "/logo.jpg"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><img parentName="p" {...{
        "src": "/logo.jpg",
        "alt": "Онлайн школа английского языка English Lifestyle с Евгенией Руденко",
        "title": "Онлайн школа английского языка English Lifestyle с Евгенией Руденко"
      }}></img></p>
    <h1 {...{
      "id": "доступен-новый-онлайн-курс-по-английскому-языку",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#%D0%B4%D0%BE%D1%81%D1%82%D1%83%D0%BF%D0%B5%D0%BD-%D0%BD%D0%BE%D0%B2%D1%8B%D0%B9-%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD-%D0%BA%D1%83%D1%80%D1%81-%D0%BF%D0%BE-%D0%B0%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%BE%D0%BC%D1%83-%D1%8F%D0%B7%D1%8B%D0%BA%D1%83",
        "aria-label": "доступен новый онлайн курс по английскому языку permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Доступен новый онлайн курс по английскому языку!`}</h1>
    <p>{`👩‍🏫 Провожу его я, `}<a parentName="p" {...{
        "href": "/about-me"
      }}>{`Евгения Руденко`}</a>{` 🤓`}<br /></p>
    <p>{`💡 `}<span style={{
        "color": "#7159c1"
      }}>{`Запишись сейчас`}</span>{`, количество учеников
`}<strong parentName="p">{`строго ограничено`}</strong>{`!`}</p>
    <p>{`О формате и способах участия я расскажу дальше, а пока давай разберем для кого этот курс.`}</p>
    <h2 {...{
      "id": "этот-курс-для-тебя-если",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%D1%8D%D1%82%D0%BE%D1%82-%D0%BA%D1%83%D1%80%D1%81-%D0%B4%D0%BB%D1%8F-%D1%82%D0%B5%D0%B1%D1%8F-%D0%B5%D1%81%D0%BB%D0%B8",
        "aria-label": "этот курс для тебя если permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Этот курс для тебя, если:`}</h2>
    <ul>
      <li parentName="ul">{`🙇‍♂ ты уже изучал английский, но нет четкой базы в голове и ощущения, что вся информация разложена `}{`"`}{`по полочкам`}{`"`}</li>
      <li parentName="ul">{`📖 твой английский - это `}{`"`}{`читаю и перевожу со словарем, частично понимаю на слух, но ответить не могу (или стесняюсь)`}{`"`}{`.`}</li>
      <li parentName="ul">{`🙋‍♀ у тебя синдром отличницы и ты считаешь, что должен непременно знать все, вызубрить грамматику, лексику и отточить готовые фразы для общения (`}<em parentName="li">{`приходи на курс - мы тебя вылечим!`}</em>{` И покажем совершенно новый формат работы и обучения :))`}</li>
      <li parentName="ul">{`💬 тебе необходимо вспомнить базовую грамматику (или укрепить имеющиеся знания)`}</li>
      <li parentName="ul">{`👀 ты считаешь, что не можешь построить предложения на английском, у тебя языковой барьер и общение с носителем языка вызывает страх`}</li>
      <li parentName="ul">{`🗣 ты не понимаешь быструю речь носителей языка (считаешь, что у них `}{`"`}{`каша во рту`}{`"`}{` и они очень невнятно говорят)`}</li>
      <li parentName="ul">{`🎶 у тебя отсутствуют базовые знания фонетики английского языка (не знаешь ничего про ритм, интонацию, связное произношение и такие важные приемы разговорной речи, как редукция, ассимиляция и тд)`}</li>
      <li parentName="ul">{`🤦‍♂ говоришь по-английски с русским акцентом и хочешь качественно улучшить свое произношение и звучать более естественно`}</li>
      <li parentName="ul">{`👩‍🎓 хочешь выйти на новый уровень аудирования (восприятия речи на слух) и говорения`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`👉🏻 Далее изучи подробнее `}<a parentName="p" {...{
          "href": "/improve-your-english"
        }}>{`чему ты научишься`}</a>{` пройдя `}<a parentName="p" {...{
          "href": "/course/programm"
        }}>{`онлайн курс`}</a>{`!`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`📌 Если не удалось разобраться как записаться на курс или остались дополнительные вопросы, пиши в директ `}<a href="https://www.instagram.com/eugeniya_english/" target="_blank">{`@eugeniya_english`}</a>{` или в Телеграме `}<a href="https://tlgg.ru/JaneRudenko" target="_blank">{`@JaneRudenko`}</a></p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      